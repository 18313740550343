import React from 'react';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';

import useEventListener from '@utils/use-event-listener';
import ImagePreview from '@components/ImagePreview';
import ArticleTitle from '@components/ArticleTitle';
import ArticleContent from '@components/ArticleContent';
import { ContentWrapper } from '@styles/layouts/ContentWrapper/ContentWrapper.styles';
import { css } from 'styled-components';
import { getPreviousPage } from '../../utils/previousPage';

const Article = (props) => {
  const { pathname, origin } = useLocation();
  const {
    title,
    author,
    date,
    timeForRead,
    headerImageUrl,
    sections,
    articleHeaderImages,
  } = props.pageContext ? props.pageContext.article : {};
  const sources = articleHeaderImages?.map((image) => ({
    ...image.src.childImageSharp.gatsbyImageData,
    ...(image.minWidth && { media: `(min-width: ${image.minWidth}px)` }),
  })) ?? [headerImageUrl.childImageSharp.gatsbyImageData];

  const state = {
    state: {
      articlesToShow: props.location.state
        ? props.location.state.articlesToShow
        : 3,
    },
  };

  const previousPage = getPreviousPage(pathname);

  useEventListener(
    typeof window !== 'undefined' && window,
    'keydown',
    (event) => {
      const ESC_KEY_CODE = 27;
      const { keyCode } = event;

      if (keyCode === ESC_KEY_CODE) {
        navigate(previousPage);
      }
    },
  );

  const actionButtonProps = {
    to: previousPage,
    state,
  };

  const articleTitle = `${title} | Depop newsroom`;
  const articleImage = headerImageUrl?.childImageSharp?.gatsbyImageData
    && headerImageUrl?.childImageSharp?.gatsbyImageData?.src;
  const articleImageLink = `${origin}${articleImage}`;
  const descriptionText = sections
    .find(({ type }) => type === 'text')
    .value.replace(/<[^>]*>?/gm, '');
  const metaDescriptionText = `${descriptionText.slice(0, 157)}...`;
  return (
    <ModalRoutingContext.Consumer>
      {() => (
        <>
          <Helmet>
            <title>{articleTitle}</title>
            <meta itemProp="image" content={articleImageLink} />
            <meta property="og:image" content={articleImageLink} />
            <meta property="twitter:image" content={articleImageLink} />
            <meta property="og:title" content={title} />
            <meta property="twitter:title" content={title} />
            <meta name="description" content={metaDescriptionText} />
            <meta itemProp="description" content={metaDescriptionText} />
            <meta property="og:description" content={metaDescriptionText} />
            <meta property="twitter:description" content={metaDescriptionText} />
          </Helmet>
          <ImagePreview
            sources={sources}
            cssRules={css`
              a {
                margin-top: 50px;
              }
            `}
            imageUrl={headerImageUrl}
            actionButtonProps={actionButtonProps}
          />
          <ContentWrapper>
            <ArticleTitle
              date={date}
              title={title}
              author={author}
              isPrimaryHeading
              timeForRead={timeForRead}
            />
            <ArticleContent content={sections} />
          </ContentWrapper>
        </>
      )}
    </ModalRoutingContext.Consumer>
  );
};

export default Article;
